import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import IntroText from '../components/IntroText';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <>
    {children}
  </>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Disclosure`}</h1>
    <IntroText text="Hello fellow designers and developers." mdxType="IntroText" />
    <p>{`csspurge.com is a personal blog to help you improve your craft and share my journey as a web designer.`}</p>
    <p>{`We use affiliate links as a way to help us support the running of this blog.`}</p>
    <p>{`If you click on one of these links and make a purchase, csspurge.com will earn a commission, at no extra cost to you because the products are the same price as if you bought them without going through an affiliate link.`}</p>
    <p>{`To keep up with the industry, I have taken on courses to help sharpen the skills and explore new technologies.`}</p>
    <p>{`We’re careful about the products/services we recommend.`}</p>
    <p>{`So if you purchase a product/service with an affiliate link on this site, it is a great way to support us.`}</p>
    <p>{`Affiliate links are marked, so you can make an informed decision.`}</p>
    <p>{`Stay hungry!`}</p>
    <p>{`Roy`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      